import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

function UploadComponents({ fieldName, productId, equipmentType }) {
  const [progress, setProgress] = useState(0);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [isOpen, setIsOpen] = useState(false);

  function updatePercentage(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setProgress(percentCompleted);
  }

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      let completedLinks = [];
      acceptedFiles.forEach(file => {
        const yearValue = fieldName;
        const fileName = file.name;
        axios
          .get(`api/products/upload-components/generateFileUploadSignedLink`, {
            params: {
              productId,
              equipmentType,
              year: yearValue,
              fileName,
            },
          })
          .then(link => {
            const options = {
              headers: { 'Content-Type': 'application/octet-stream' },
              onUploadProgress: updatePercentage,
            };
            const instance = axios.create();
            delete instance.defaults.headers.common['Authorization'];
            instance.put(link.data, file, options).then(() => {
              const splitElement = link.data.split('?')[0];
              completedLinks.push({ fileName, link: splitElement });
              if (completedLinks.length === acceptedFiles.length) {
                axios.post(`api/products/update-components-link/${productId}/${yearValue}/${equipmentType}`, completedLinks);
                setIsOpen(false);
              }
            });
          });
      });
    }
  }, [acceptedFiles]);

  const files = acceptedFiles.map(file => <li>{file.name}</li>);

  return (
    <div className="form-group file-area create-product-file-upload" onClick={() => setIsOpen(!isOpen)}>
      {progress > 0 ? <div className="text-center">{progress}%</div> : ''}

      {progress > 0 && progress < 100 ? 'Uploading . . .' : progress === 100 ? 'Completed' : 'Upload Components file'}

      <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <ModalHeader toggle={() => setIsOpen(!isOpen)}>Modal title</ModalHeader>
        <ModalBody className={'file-area'}>
          <section className="container file-dummy file-area">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside>
              {acceptedFiles.length > 0 ? (
                <>
                  <h6>Components</h6>
                  <ul>{files}</ul>
                </>
              ) : (
                ''
              )}
            </aside>
          </section>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default UploadComponents;
