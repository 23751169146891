import React from 'react';
import './editManufacturer.scss';
import Divider from 'app/components/Divider/Divider';

const Sidebar = ({ activeTab, setActiveTab }) => {
  return (
    <div className="editManufacturer-sidebar-container">
      <div className="editManufacturer-sidebar-header">
        <h2 className="editManufacturer-sidebar-header-title">Edit MFR</h2>
        <span className="editManufacturer-sidebar-header-subtitle">Choose between categories</span>
        <Divider length="95%" />
      </div>

      <div className="editManufacturer-sidebar">
        <div className={`editManufacturer-tab ${activeTab === 1 ? 'editManufacturer-active' : ''}`} onClick={() => setActiveTab(1)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M10 11.5V13C8.80653 13 7.66193 13.4741 6.81802 14.318C5.97411 15.1619 5.5 16.3065 5.5 17.5H4C4 15.9087 4.63214 14.3826 5.75736 13.2574C6.88258 12.1321 8.4087 11.5 10 11.5V11.5ZM10 10.75C7.51375 10.75 5.5 8.73625 5.5 6.25C5.5 3.76375 7.51375 1.75 10 1.75C12.4862 1.75 14.5 3.76375 14.5 6.25C14.5 8.73625 12.4862 10.75 10 10.75ZM10 9.25C11.6575 9.25 13 7.9075 13 6.25C13 4.5925 11.6575 3.25 10 3.25C8.3425 3.25 7 4.5925 7 6.25C7 7.9075 8.3425 9.25 10 9.25ZM11.9462 15.109C11.8512 14.7088 11.8512 14.2919 11.9462 13.8917L11.2022 13.462L11.9522 12.163L12.6962 12.5927C12.9949 12.3099 13.3558 12.1013 13.75 11.9838V11.125H15.25V11.9838C15.649 12.1023 16.009 12.3137 16.3037 12.5927L17.0477 12.163L17.7977 13.462L17.0537 13.8917C17.1487 14.2917 17.1487 14.7083 17.0537 15.1083L17.7977 15.538L17.0477 16.837L16.3037 16.4072C16.0051 16.6901 15.6442 16.8987 15.25 17.0162V17.875H13.75V17.0162C13.3558 16.8987 12.9949 16.6901 12.6962 16.4072L11.9522 16.837L11.2022 15.538L11.9462 15.109V15.109ZM14.5 15.625C14.7984 15.625 15.0845 15.5065 15.2955 15.2955C15.5065 15.0845 15.625 14.7984 15.625 14.5C15.625 14.2016 15.5065 13.9155 15.2955 13.7045C15.0845 13.4935 14.7984 13.375 14.5 13.375C14.2016 13.375 13.9155 13.4935 13.7045 13.7045C13.4935 13.9155 13.375 14.2016 13.375 14.5C13.375 14.7984 13.4935 15.0845 13.7045 15.2955C13.9155 15.5065 14.2016 15.625 14.5 15.625Z"
              fill={activeTab === 1 ? '#FA8519' : '#525866'}
            />
          </svg>
          <span style={{ marginLeft: 6 }}> Manufacturer Details</span>
        </div>
        <div className={`editManufacturer-tab ${activeTab === 2 ? 'editManufacturer-active' : ''}`} onClick={() => setActiveTab(2)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M3.25 15.25V5.27501C3.24995 5.12106 3.29729 4.97082 3.38558 4.84469C3.47386 4.71857 3.59883 4.62267 3.7435 4.57001L10.9968 1.93301C11.0534 1.91239 11.1142 1.90574 11.174 1.91361C11.2338 1.92149 11.2908 1.94366 11.3401 1.97825C11.3895 2.01284 11.4298 2.05883 11.4577 2.11232C11.4855 2.16581 11.5 2.22522 11.5 2.28551V6.00026L16.237 7.57901C16.3864 7.62877 16.5164 7.7243 16.6084 7.85205C16.7005 7.9798 16.75 8.13329 16.75 8.29076V15.25H18.25V16.75H1.75V15.25H3.25ZM4.75 15.25H10V3.89126L4.75 5.80076V15.25ZM15.25 15.25V8.83151L11.5 7.58126V15.25H15.25Z"
              fill={activeTab === 2 ? '#FA8519' : '#525866'}
            />
          </svg>
          <span style={{ marginLeft: 6 }}>MFR CRM Integrations</span>
        </div>
        <div className={`editManufacturer-tab ${activeTab === 3 ? 'editManufacturer-active' : ''}`} onClick={() => setActiveTab(3)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M11.0057 4C10.8358 4.48182 10.7493 4.98909 10.75 5.5H4.75V16H15.25V10C15.7609 10.0007 16.2682 9.91417 16.75 9.74425V16.75C16.75 16.9489 16.671 17.1397 16.5303 17.2803C16.3897 17.421 16.1989 17.5 16 17.5H4C3.80109 17.5 3.61032 17.421 3.46967 17.2803C3.32902 17.1397 3.25 16.9489 3.25 16.75V4.75C3.25 4.55109 3.32902 4.36032 3.46967 4.21967C3.61032 4.07902 3.80109 4 4 4H11.0057ZM15.25 7C15.6478 7 16.0294 6.84196 16.3107 6.56066C16.592 6.27936 16.75 5.89782 16.75 5.5C16.75 5.10218 16.592 4.72064 16.3107 4.43934C16.0294 4.15804 15.6478 4 15.25 4C14.8522 4 14.4706 4.15804 14.1893 4.43934C13.908 4.72064 13.75 5.10218 13.75 5.5C13.75 5.89782 13.908 6.27936 14.1893 6.56066C14.4706 6.84196 14.8522 7 15.25 7V7ZM15.25 8.5C14.4544 8.5 13.6913 8.18393 13.1287 7.62132C12.5661 7.05871 12.25 6.29565 12.25 5.5C12.25 4.70435 12.5661 3.94129 13.1287 3.37868C13.6913 2.81607 14.4544 2.5 15.25 2.5C16.0456 2.5 16.8087 2.81607 17.3713 3.37868C17.9339 3.94129 18.25 4.70435 18.25 5.5C18.25 6.29565 17.9339 7.05871 17.3713 7.62132C16.8087 8.18393 16.0456 8.5 15.25 8.5Z"
              fill={activeTab === 3 ? '#FA8519' : '#525866'}
            />
          </svg>
          <span style={{ marginLeft: 6 }}> Email Templates</span>
        </div>
        <div className={`editManufacturer-tab ${activeTab === 4 ? 'editManufacturer-active' : ''}`} onClick={() => setActiveTab(4)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M2.5 17.5C2.5 15.9087 3.13214 14.3826 4.25736 13.2574C5.38258 12.1321 6.9087 11.5 8.5 11.5C10.0913 11.5 11.6174 12.1321 12.7426 13.2574C13.8679 14.3826 14.5 15.9087 14.5 17.5H13C13 16.3065 12.5259 15.1619 11.682 14.318C10.8381 13.4741 9.69347 13 8.5 13C7.30653 13 6.16193 13.4741 5.31802 14.318C4.47411 15.1619 4 16.3065 4 17.5H2.5ZM8.5 10.75C6.01375 10.75 4 8.73625 4 6.25C4 3.76375 6.01375 1.75 8.5 1.75C10.9863 1.75 13 3.76375 13 6.25C13 8.73625 10.9863 10.75 8.5 10.75ZM8.5 9.25C10.1575 9.25 11.5 7.9075 11.5 6.25C11.5 4.5925 10.1575 3.25 8.5 3.25C6.8425 3.25 5.5 4.5925 5.5 6.25C5.5 7.9075 6.8425 9.25 8.5 9.25ZM14.713 12.0273C15.767 12.5019 16.6615 13.2709 17.2889 14.2418C17.9164 15.2126 18.2501 16.344 18.25 17.5H16.75C16.7502 16.633 16.4999 15.7844 16.0293 15.0562C15.5587 14.328 14.8878 13.7512 14.0972 13.3953L14.7123 12.0273H14.713ZM14.197 3.55975C14.9526 3.87122 15.5987 4.40015 16.0533 5.07942C16.5078 5.75869 16.7503 6.55768 16.75 7.375C16.7503 8.40425 16.3658 9.39642 15.6719 10.1566C14.978 10.9168 14.025 11.3901 13 11.4835V9.97375C13.5557 9.89416 14.0713 9.63851 14.471 9.24434C14.8707 8.85017 15.1335 8.33824 15.2209 7.7837C15.3082 7.22916 15.2155 6.66122 14.9563 6.16327C14.6971 5.66531 14.2851 5.26356 13.7808 5.017L14.197 3.55975V3.55975Z"
              fill={activeTab === 4 ? '#FA8519' : '#525866'}
            />
          </svg>
          <span style={{ marginLeft: 6 }}> Mfr Rep Emails</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
