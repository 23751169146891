import React, { useState } from 'react';
import axios from 'axios';

function UploadFile({ fieldName, productId, equipmentType }) {
  const [progress, setProgress] = useState(0);

  function updatePercentage(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setProgress(percentCompleted);
  }

  const onBlobChange = year => event => {
    const file = event.target.files[0];
    const yearValue = year;
    const fileName = event.target.files[0].name;
    axios
      .get(`api/products/generateFileUploadSignedLink`, {
        params: {
          productId,
          equipmentType,
          year: yearValue,
          fileName,
        },
      })
      .then(link => {
        const options = {
          headers: { 'Content-Type': 'application/octet-stream' },
          onUploadProgress: updatePercentage,
        };
        const instance = axios.create();
        delete instance.defaults.headers.common['Authorization'];
        instance.put(link.data, file, options).then(() => {
          // axios.post(`api/products//${productId}/refresh-available-file-versions`);
        });
      });
  };
  return (
    <div className="form-group file-area create-product-file-upload" style={{ marginRight: 8 }}>
      <input id="year2022" type="file" onChange={onBlobChange(fieldName)} />

      {progress > 0 ? <div className="text-center">{progress}%</div> : ''}
      <div className="default">
        {progress > 0 && progress < 100 ? 'Uploading . . .' : progress === 100 ? 'Completed' : 'Please select file'}
      </div>
    </div>
  );
}

export default UploadFile;
