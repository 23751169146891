import React, { useCallback, useEffect, useRef, useState } from 'react';
import UploadComponents from 'app/entities/product/UploadComponents';
import UploadFile from 'app/entities/product/UploadFile';
import RoomleConfiguratorApi from '@roomle/embedding-lib/roomle-configurator-api.es.min.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEnabledManufacturers } from 'app/entities/manufacturer/manufacturer.reducer';
import { IRootState } from 'app/shared/reducers';
import { AvFeedback, AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Collapse, Label, Row } from 'reactstrap';
import { createEntity, getEntity, reset, updateEntity } from './product.reducer';
import { FOOD_SERVICE_EQUIPMENT, SPECIALITY_EQUIPMENT } from 'app/config/constants';
import { setIsSidebarCollapsed } from '../user-settings/user-settings.reducer';
import { setIsFullScreenMode } from '../user-settings/user-settings.reducer';
import './product.scss';
import Divider from 'app/components/Divider/Divider';
import '../manufacturer/editManufacturer.scss';

export interface IProductUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

const initialProgress = { 2017: 0, 2018: 0, 2019: 0, 2020: 0, 2021: 0, 2022: 0 };
export const ProductUpdate = (props: IProductUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [savingEntity, setSavingEntity] = useState(false);
  const [configurationId, setConfigurationId] = useState(null);
  const [filename, setFilename] = useState('');
  const [roomleConfiguration, setRoomleConfiguration] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isButtonEnabled, setIsButtonEnable] = useState(false);
  const [files, setFiles] = useState({});
  const [update, setUpdate] = useState(true);
  const [progress, setProgress] = useState(initialProgress);
  const [rootTenantId, setRootTenantId] = useState('');

  const ref2017 = useRef(null);
  const ref2018 = useRef(null);
  const ref2019 = useRef(null);
  const ref2020 = useRef(null);
  const ref2021 = useRef(null);
  const ref2022 = useRef(null);

  const { productEntity, manufacturers, loading, updating } = props;

  const handleClose = () => {
    props.reset();
    props.history.push('/product' + props.location.search);
  };

  useEffect(() => {
    if ((!configurationId && !rootTenantId) || props.loading) return;

    (async () => {
      const options = {
        locale: 'en',
        moc: false,
        translations: {
          en: {
            params: {
              'request-product': 'Save Configuration',
            },
          },
        },
      };

      let rootTenantIdToLoad = '';

      if (rootTenantId) {
        rootTenantIdToLoad = rootTenantId;
      } else {
        rootTenantIdToLoad = configurationId.split(':')[0];
      }

      const configurator = await RoomleConfiguratorApi.create(rootTenantIdToLoad, document.getElementById('configurator-container'), {
        ...options,
        buttons: {
          savedraft: false,
          startconfigure: false,
          requestproduct: false,
          export3d: false,
        },
      });

      await configurator.ui.loadObject(configurationId);

      configurator.ui.callbacks.onRequestProduct = (confId, image, partlist, price, labels, configuration) => {
        setRoomleConfiguration(configuration);
        setIsButtonEnable(true);
      };
      configurator.ui.triggerRequestProduct();
    })();
  }, [configurationId, rootTenantId, props.loading]);

  useEffect(() => {
    if (props.productEntity != null && Object.keys(props.productEntity).length) {
      setConfigurationId(props.productEntity.roomleComponentId);
      setRootTenantId(props.productEntity.roomleTenantId);
    }
  }, [props.productEntity]);

  useEffect(() => {
    props.setIsSidebarCollapsed(true);
    props.setIsFullScreenMode(true);
    setConfigurationId(null);
    setRoomleConfiguration(null);
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getEnabledManufacturers();

    return () => {
      props.setIsSidebarCollapsed(false);
      props.setIsFullScreenMode(false);
    };
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      // handleClose();
      setSavingEntity(false);
    }
  }, [props.updateSuccess]);

  const getParameterMapping = useCallback(roomleConfig => {
    const parsedJson = JSON.parse(roomleConfig.configuration);
    if (parsedJson && parsedJson.parameters && parsedJson.parameters.parameterlabel) {
      const parametersMappingHeaders = parsedJson.parameters.parameterlabel.split(',');
      const parametersMappingValues = parsedJson.parameters.parameterlabelvalue.split(',');

      const parametersMapping = {};

      const length = parametersMappingHeaders.length;
      for (let i = 0; i < length; i++) {
        const header =
          parametersMappingHeaders[i] === '' || parametersMappingHeaders[i] === null ? 'Model Number' : parametersMappingHeaders[i];

        if (parametersMappingValues[i] !== null && parametersMappingValues[i] === 'null') {
          continue;
        }
        parametersMapping[header] = parametersMappingValues[i];
      }

      return parametersMapping;
    } else {
      return '';
    }
  }, []);

  const getHashConfiguration = roomleConfig => {
    return roomleConfig.configurationHash;
  };

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const parsedJson = JSON.parse(roomleConfiguration.configuration);
      let parameters = '';
      if (parsedJson && parsedJson.parameters && parsedJson.parameters.parameterlabel) {
        const parametersMappingHeaders = parsedJson.parameters.parameterlabel.split(',');
        const parametersMappingValues = parsedJson.parameters.parameterlabelvalue.split(',');
        parameters = parametersMappingHeaders + '\\n' + parametersMappingValues;
      }
      const typecatalog = parsedJson.parameters ? parsedJson.parameters.typecatalog : '';
      const typecatalogheader = parsedJson.parameters ? parsedJson.parameters.typecatalogheader : '';
      const entity = {
        ...productEntity,
        ...values,
        parameters,
        typecatalog,
        typecatalogheader,
      };

      setSavingEntity(true);
      if (isNew) {
        props.createEntity(entity, files);
      } else {
        props.updateEntity(entity, files);
      }

      // setConfigurationId(null);
      // props.reset();
    }
  };

  const onRoomleComponentIdChange = field => {
    setConfigurationId(null);
    const value = field.target.value;
    setTimeout(function () {
      setConfigurationId(value);
    }, 500);
  };

  const onTenantComponentIdChange = field => {
    setRootTenantId(null);
    const value = field.target.value;
    setTimeout(function () {
      setRootTenantId(value);
    }, 500);
  };

  // const onBlobChange = year => event => {
  //    // files[year] = event.target.files[0];
  //    // setFilename(event.target.files[0].name);
  //    // setFiles(files);
  //    setUpdate(!update);

  //    let file = event.target.files[0];
  //    let yearValue = year;
  //    let fileName = event.target.files[0].name;

  //    axios.get(`api/products/generateFileUploadSignedLink`, {
  //      params: {
  //        productId: productEntity.id,
  //        year,
  //        fileName
  //      }
  //    }).then(link => {
  //      const options = {
  //        headers: {'Content-Type': "application/octet-stream"},
  //        onUploadProgress: function (progressEvent) {
  //          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
  //          setProgress({...progress, [yearValue]: percentCompleted})
  //        }
  //      };
  //      const instance = axios.create();
  //      delete instance.defaults.headers.common['Authorization'];
  //      // @ts-ignore
  //      instance.put(link.data, file, options).then(() => {
  //        axios.post(`api/products//${productEntity.id}/refresh-available-file-versions`);
  //      })
  //    })
  //  };

  const clearBlob = name => event => {
    files[name] = null;
    if (ref2017 !== null && name === '2017') {
      ref2017.current.value = null;
    }
    if (ref2018 !== null && name === '2018') {
      ref2018.current.value = null;
    }
    if (ref2019 !== null && name === '2019') {
      ref2019.current.value = null;
    }
    if (ref2020 !== null && name === '2020') {
      ref2020.current.value = null;
    }
    if (ref2021 !== null && name === '2021') {
      ref2021.current.value = null;
    }
    if (ref2022 !== null && name === '2022') {
      ref2022.current.value = null;
    }
    setFiles(files);
    setUpdate(!update);
  };

  const toggle = () => setIsOpen(!isOpen);

  const file2017 = files[2017] != null ? files[2017] : null;
  const file2018 = files[2018] != null ? files[2018] : null;
  const file2019 = files[2019] != null ? files[2019] : null;
  const file2020 = files[2020] != null ? files[2020] : null;
  const file2021 = files[2021] != null ? files[2021] : null;
  const file2022 = files[2022] != null ? files[2022] : null;
  const contentType = productEntity != null ? productEntity.fileContentType : '';

  return (
    <div style={{ background: '#fff', padding: 20 }}>
      <div>
        {savingEntity ? (
          <p>Saving...</p>
        ) : (
          <>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                <AvForm model={isNew ? {} : productEntity} onSubmit={saveEntity} style={{ paddingBottom: 30 }}>
                  <header className="edit-manufacturer-form-header-container">
                    <div className="edit-manufacturer-form-header">
                      <div className="d-flex align-items-center">
                        <Button tag={Link} id="cancel-save" to="/product" className="back-to-project-submission-button">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10.5 19L3.5 12L10.5 5M20.5 12L4 12" stroke="black" stroke-width="2" />
                          </svg>
                        </Button>
                        <div className="d-flex flex-column">
                          <span className="edit-product-header-name">test</span>
                          {!isNew && <span className="edit-product-header-id">ID {props.productEntity.id}</span>}
                        </div>
                      </div>

                      <div className="d-flex align-items-center">
                        {isNew && (
                          <AvGroup hidden>
                            <Label for="product-filename" className="create-product-page-label">
                              File Name
                            </Label>
                            <AvInput
                              id="product-filename"
                              type="text"
                              className="form-control"
                              name="familyFilename"
                              value={filename}
                            ></AvInput>
                          </AvGroup>
                        )}
                        <Button
                          onClick={toggle}
                          style={{ fontSize: 12, paddingHorizontal: 10, paddingVertical: 5, marginRight: 5 }}
                          disabled={!isButtonEnabled}
                          className="product-page-button product-page-button--blue"
                        >
                          Show Roomle Configuration
                        </Button>
                        <Button
                          id="save-entity"
                          type="submit"
                          className="product-page-button product-page-button--orange"
                          disabled={updating || !isButtonEnabled}
                        >
                          Save Changes
                        </Button>
                      </div>
                    </div>
                    <Divider length="100%" />
                  </header>
                  <div className="d-flex">
                    <div style={{ width: '60%', paddingRight: 50 }}>
                      <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                        <span className="edit-manufacturer-form-input-label">Name</span>
                        <div className={'w-50'}>
                          <AvField
                            id="product-name"
                            type="text"
                            name="name"
                            validate={{
                              required: { value: true, errorMessage: 'This field is required.' },
                            }}
                            className="edit-manufacturer-form-input"
                            styles={{ margin: 0 }}
                          />
                        </div>
                      </AvGroup>
                      <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                        <span className="edit-manufacturer-form-input-label">Root Tenant ID</span>
                        <div className={'w-50'}>
                          <AvField
                            id="roomleTenantId"
                            type="text"
                            name="roomleTenantId"
                            onChange={onTenantComponentIdChange}
                            className="edit-manufacturer-form-input"
                          />
                        </div>
                      </AvGroup>
                      <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                        <span className="edit-manufacturer-form-input-label"> Root Component ID</span>
                        <div className={'w-50'}>
                          <AvField
                            id="rootComponentId"
                            type="text"
                            name="rootComponentId"
                            validate={{
                              required: { value: true, errorMessage: 'This field is required.' },
                            }}
                            className="edit-manufacturer-form-input"
                          />
                        </div>
                      </AvGroup>
                      <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                        <span className="edit-manufacturer-form-input-label">Roomle Component Id</span>
                        <div className={'w-50'}>
                          <AvField
                            id="product-roomleComponentId"
                            type="text"
                            name="roomleComponentId"
                            className="edit-manufacturer-form-input"
                            onChange={onRoomleComponentIdChange}
                          />
                        </div>
                      </AvGroup>
                      <Collapse isOpen={isOpen}>
                        <Label id="parameterValues" className="create-product-page-label">
                          Parameter Mapping
                        </Label>
                        <div>
                          {roomleConfiguration && (
                            <dd style={{ background: 'lightgrey', borderRadius: '10px' }}>
                              <pre>{JSON.stringify(getParameterMapping(roomleConfiguration), null, 2)}</pre>
                            </dd>
                          )}
                        </div>
                        <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                          <span className="edit-manufacturer-form-input-label">Configuration Hash</span>
                          <div className={'w-50'}>
                            <AvField
                              id="product-configuration"
                              type="text"
                              name="configuration"
                              disabled
                              value={roomleConfiguration && getHashConfiguration(roomleConfiguration)}
                              className="edit-manufacturer-form-input"
                            />
                          </div>
                        </AvGroup>
                      </Collapse>
                      <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                        <span className="edit-manufacturer-form-input-label">Description</span>

                        <div className={'w-50'}>
                          <AvField id="product-description" type="text" name="description" className="edit-manufacturer-form-input" />
                        </div>
                      </AvGroup>
                      <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                        <span className="edit-manufacturer-form-input-label">Manufacturer</span>
                        <div className={'w-50'}>
                          <AvInput
                            id="product-manufacturer"
                            type="select"
                            className="create-product-dropdown form-control"
                            name="manufacturerId"
                            required
                          >
                            {manufacturers
                              ? manufacturers.map(manufacturer => (
                                  <option value={manufacturer.id} key={manufacturer.id}>
                                    {manufacturer.name}
                                  </option>
                                ))
                              : null}
                          </AvInput>
                          <AvFeedback>This field is required.</AvFeedback>
                        </div>
                      </AvGroup>
                      <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                        <span className="edit-manufacturer-form-input-label">Base URL</span>
                        <div className={'w-50'}>
                          <AvField id="baseURL" type="text" name="baseURL" className="edit-manufacturer-form-input" />
                        </div>
                      </AvGroup>
                      <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                        <span className="edit-manufacturer-form-input-label">Revit Project File</span>
                        <div className={'w-50'}>
                          <AvField id="revitProjectFile" type="text" name="revitProjectFile" className="edit-manufacturer-form-input" />
                        </div>
                      </AvGroup>

                      <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                        <span className="edit-manufacturer-form-input-label">Roomle Origin</span>
                        <div className={'w-50'}>
                          <AvInput
                            id="roomleOrigin"
                            type="select"
                            className="create-product-dropdown form-control"
                            name="roomleOrigin"
                            required
                          >
                            <option value={''}>Select item</option>
                            <option value={'LEFT'}>Left</option>
                            <option value={'CENTER'}>Center</option>
                          </AvInput>
                          <AvFeedback>This field is required.</AvFeedback>
                        </div>
                      </AvGroup>
                      <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                        <span className="edit-manufacturer-form-input-label">Product Type</span>
                        <div className={'w-50'}>
                          <AvInput id="productType" type="select" className="create-product-dropdown form-control" name="productType">
                            <option value={''}>Select item</option>
                            <option value={'SINGLE_FAMILY'}>Single Family</option>
                            <option value={'SINGLE_FAMILY_WITH_ACCESSORIES'}>Single Family With Accessories</option>
                            <option value={'LINE_UP_FAMILY'}>Line Up Family</option>
                          </AvInput>
                        </div>
                        <AvFeedback>This field is required.</AvFeedback>
                      </AvGroup>
                      {!isNew && (
                        <>
                          <h5 className={'mt-5 mb-3 create-product-page-title'}>Specialty Equipment</h5>
                          <Divider length="100%" />
                          <div className={'mt-3 create-product-page-uploads-container'}>
                            <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                              <Label id="familyFilePathLabel-2019" for="product-familyFilePath-2019" className="create-product-page-label">
                                Family File Path 2019
                              </Label>
                              <br />
                              <Row>
                                <UploadFile equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2019'} productId={productEntity.id} />
                                <UploadComponents equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2019'} productId={productEntity.id} />
                              </Row>
                            </AvGroup>
                            <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                              <Label id="familyFilePathLabel-2020" for="product-familyFilePath-2020" className="create-product-page-label">
                                Family File Path 2020
                              </Label>
                              <br />
                              <Row>
                                <UploadFile equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2020'} productId={productEntity.id} />
                                <UploadComponents equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2020'} productId={productEntity.id} />
                              </Row>
                            </AvGroup>
                            <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                              <Label id="familyFilePathLabel-2021" for="product-familyFilePath-2021" className="create-product-page-label">
                                Family File Path 2021
                              </Label>
                              <br />
                              <Row>
                                <UploadFile equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2021'} productId={productEntity.id} />
                                <UploadComponents equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2021'} productId={productEntity.id} />
                              </Row>
                            </AvGroup>
                            <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                              <Label id="familyFilePathLabel-2022" for="product-familyFilePath-2022" className="create-product-page-label">
                                Family File Path 2022
                              </Label>
                              <br />
                              <Row>
                                <UploadFile equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2022'} productId={productEntity.id} />
                                <UploadComponents equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2022'} productId={productEntity.id} />
                              </Row>
                            </AvGroup>
                            <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                              <Label id="familyFilePathLabel-2022" for="product-familyFilePath-2023" className="create-product-page-label">
                                Family File Path 2023
                              </Label>
                              <br />
                              <Row>
                                <UploadFile equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2023'} productId={productEntity.id} />
                                <UploadComponents equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2023'} productId={productEntity.id} />
                              </Row>
                            </AvGroup>
                            <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                              <Label id="familyFilePathLabel-2024" for="product-familyFilePath-2024" className="create-product-page-label">
                                Family File Path 2024
                              </Label>
                              <br />
                              <Row>
                                <UploadFile equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2024'} productId={productEntity.id} />
                                <UploadComponents equipmentType={SPECIALITY_EQUIPMENT} fieldName={'2024'} productId={productEntity.id} />
                              </Row>
                            </AvGroup>
                          </div>
                          <h5 className={'mt-5 mb-3 create-product-page-title'}>Food Service Equipment</h5>
                          <Divider length="100%" />
                          <div className={'mt-3 create-product-page-uploads-container'}>
                            <>
                              <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                                <Label
                                  id="familyFilePathLabel-2022"
                                  for="product-familyFilePath-2022"
                                  className="create-product-page-label"
                                >
                                  Family File Path 2022
                                </Label>
                                <br />
                                <Row>
                                  <UploadFile equipmentType={FOOD_SERVICE_EQUIPMENT} fieldName={'2022'} productId={productEntity.id} />
                                  <UploadComponents
                                    equipmentType={FOOD_SERVICE_EQUIPMENT}
                                    fieldName={'2022'}
                                    productId={productEntity.id}
                                  />
                                </Row>
                              </AvGroup>
                              <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                                <Label
                                  id="familyFilePathLabel-2023"
                                  for="product-familyFilePath-2023"
                                  className="create-product-page-label"
                                >
                                  Family File Path 2023
                                </Label>
                                <br />
                                <Row>
                                  <UploadFile equipmentType={FOOD_SERVICE_EQUIPMENT} fieldName={'2023'} productId={productEntity.id} />
                                  <UploadComponents
                                    equipmentType={FOOD_SERVICE_EQUIPMENT}
                                    fieldName={'2023'}
                                    productId={productEntity.id}
                                  />
                                </Row>
                              </AvGroup>
                              <AvGroup className="mb-3 edit-manufacturer-form-input-container">
                                <Label
                                  id="familyFilePathLabel-2024"
                                  for="product-familyFilePath-2024"
                                  className="create-product-page-label"
                                >
                                  Family File Path 2024
                                </Label>
                                <br />
                                <Row>
                                  <UploadFile equipmentType={FOOD_SERVICE_EQUIPMENT} fieldName={'2024'} productId={productEntity.id} />
                                  <UploadComponents
                                    equipmentType={FOOD_SERVICE_EQUIPMENT}
                                    fieldName={'2024'}
                                    productId={productEntity.id}
                                  />
                                </Row>
                              </AvGroup>
                            </>
                          </div>
                        </>
                      )}
                    </div>

                    <div style={{ width: '40%', paddingLeft: 20 }}>
                      {configurationId !== null && configurationId !== '' ? (
                        <div id="configurator-container" className="create-product-configurator-container"></div>
                      ) : null}
                      <div className="create-product-page-checkboxes-container">
                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label
                            id="exportWrittenSpecs"
                            for="product-exportWrittenSpecs"
                            className="create-product-page-label create-product-page-checkbox-label"
                          >
                            Export Written Specs
                          </Label>
                          <AvInput
                            id="product-exportWrittenSpecs"
                            type="checkbox"
                            className="checkbox-form-control"
                            name="exportWrittenSpecs"
                          />
                        </AvGroup>

                        <AvGroup check className="create-product-page-checkbox-group ">
                          <Label
                            id="cleanRevitFamily"
                            for="product-cleanRevitFamily"
                            className="create-product-page-label create-product-page-checkbox-label"
                          >
                            Clean Revit Family
                          </Label>
                          <AvInput
                            id="product-cleanRevitFamily"
                            type="checkbox"
                            className="checkbox-form-control"
                            name="cleanRevitFamily"
                          />
                        </AvGroup>

                        <AvGroup check className="create-product-page-checkbox-group ">
                          <Label
                            id="showRevitSetMountingHeight"
                            for="product-showRevitSetMountingHeight"
                            className="create-product-page-label create-product-page-checkbox-label"
                          >
                            Show Revit Set Mounting Height
                          </Label>
                          <AvInput
                            id="product-showRevitSetMountingHeight"
                            type="checkbox"
                            className="checkbox-form-control"
                            name="showRevitSetMountingHeight"
                          />
                        </AvGroup>

                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label
                            id="complexFamilyLabel"
                            for="product-complexFamily"
                            className="create-product-page-label create-product-page-checkbox-label"
                          >
                            Is Complex Family
                          </Label>
                          <AvInput id="product-complexFamily" type="checkbox" className="checkbox-form-control" name="complexFamily" />
                        </AvGroup>
                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label id="isMOC" for="product-isMOC" className="create-product-page-label create-product-page-checkbox-label">
                            Is this a Multi-Object Configurator?
                          </Label>
                          <AvInput id="product-isMOC" type="checkbox" className="checkbox-form-control" name="isMOC" />
                        </AvGroup>
                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label
                            id="visibleToAdmin"
                            for="product-visibleToAdmin"
                            className="create-product-page-label create-product-page-checkbox-label"
                          >
                            Only Visible for Admin Users
                          </Label>
                          <AvInput id="product-visibleToAdmin" type="checkbox" className="checkbox-form-control" name="visibleToAdmin" />
                        </AvGroup>

                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label id="showInMarketPlaceLabel" className="create-product-page-label create-product-page-checkbox-label">
                            Show In Market Place
                          </Label>
                          <AvInput
                            id="product-showInMarketPlace"
                            type="checkbox"
                            className="checkbox-form-control"
                            name="showInMarketPlace"
                          />
                        </AvGroup>

                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label id="revitExportEnable" className="create-product-page-label create-product-page-checkbox-label">
                            Revit Export Enable
                          </Label>
                          <AvInput
                            id="product-revitExportEnable"
                            type="checkbox"
                            className="checkbox-form-control"
                            name="revitExportEnable"
                          />
                        </AvGroup>
                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label id="hideSelectedOptions" className="create-product-page-label create-product-page-checkbox-label">
                            Hide Selected Options ?
                          </Label>
                          <AvInput
                            id="product-hideSelectedOptions"
                            type="checkbox"
                            className="checkbox-form-control"
                            name="hideSelectedOptions"
                          />
                        </AvGroup>
                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label id="hideParts" className="create-product-page-label create-product-page-checkbox-label">
                            Hide Parts ?
                          </Label>
                          <AvInput id="product-hideParts" type="checkbox" className="checkbox-form-control" name="hideParts" />
                        </AvGroup>

                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label id="lineUpFamily" className="create-product-page-label create-product-page-checkbox-label">
                            Is Line Up Family ?
                          </Label>
                          <AvInput id="product-lineUpFamily" type="checkbox" className="checkbox-form-control" name="lineUpFamily" />
                        </AvGroup>

                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label id="active" className="create-product-page-label create-product-page-checkbox-label">
                            Active ?
                          </Label>
                          <AvInput id="product-active" type="checkbox" className="checkbox-form-control" name="active" />
                        </AvGroup>

                        <AvGroup check className="create-product-page-checkbox-group">
                          <Label id="selectComponentAfterDocking" className="create-product-page-label create-product-page-checkbox-label">
                            Select Component After Docking ?
                          </Label>
                          <AvInput
                            id="product-selectComponentAfterDocking"
                            type="checkbox"
                            className="checkbox-form-control"
                            name="selectComponentAfterDocking"
                          />
                        </AvGroup>
                      </div>
                    </div>
                  </div>
                </AvForm>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  account: storeState.authentication.account,
  manufacturers: storeState.manufacturer.entities,
  productEntity: storeState.product.entity,
  loading: storeState.product.loading,
  updating: storeState.product.updating,
  updateSuccess: storeState.product.updateSuccess,
  errorMessage: storeState.product.errorMessage,
});

const mapDispatchToProps = {
  getEnabledManufacturers,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  setIsSidebarCollapsed,
  setIsFullScreenMode,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProductUpdate);
