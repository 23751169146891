import React, { memo } from 'react';
import { Modal, Input } from 'reactstrap';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import './shareConfigurationByEmailModal.scss';
import '../CreateFolderModal/createFolderModal.scss';

interface IShareConfigurationByEmailModal {
  isOpen: Boolean;
  setShareEmail: Function;
  shareConfiguration: Function;
  setIsShareConfigurationByEmailModalOpen: Function;
  title?: string;
  isShareButtonWasPressed?: boolean;
  saveTo: string;
  setIsHelpVideoPopupOpen?: Function;
}

const ShareConfigurationByEmailModal = memo((props: IShareConfigurationByEmailModal) => {
  return (
    <Modal
      isOpen={props.isOpen}
      centered
      fade={false}
      style={{ maxWidth: 700, width: window.screen.width - 1000 }}
      toggle={props.setIsShareConfigurationByEmailModalOpen}
      backdrop={true}
    >
      <div className="create-folder-modal-container">
        <span className="create-folder-modal-title">{props.title ? props.title : 'Share Revit by Email'}</span>

        <Input
          placeholder="Enter Email Address..."
          onChange={e => props.setShareEmail(e.target.value)}
          className="create-folder-modal-input"
        />
        <div className="create-folder-modal-actions-container">
          <ConfirmButton title="Send" confirmAction={props.shareConfiguration} />
        </div>
        {!props.isShareButtonWasPressed && (
          <span className="share-revit-disclamer">
            To insert this group of Revit families into your Revit project, click {props.saveTo},
            <Link to="/version">Install the Kreator Revit Add-In</Link>, and use the Kreator app inside Revit to create and place Revit
            families directly into your Revit project.
            <Tippy content="Help Video" placement="bottom" theme="light-border">
              <img
                onClick={() => props.setIsHelpVideoPopupOpen(true)}
                src="../../../content/images/icons/help-video-icon.svg"
                alt="help"
                width="15"
                className="share-revit-disclamer-help-icon"
              />
            </Tippy>
          </span>
        )}
      </div>
    </Modal>
  );
});

export default ShareConfigurationByEmailModal;
